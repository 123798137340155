import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {FaFacebook, FaInstagram, FaWhatsapp, FaEnvelope, FaLink} from 'react-icons/fa';
import IconButton from "@mui/material/IconButton";
import {Popover, Typography} from "@mui/material";

const ShareDrawer = ({ isOpen, onClose, urlPerso }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleCopyLink = (event) => {
        try {
            navigator.clipboard.writeText("https://faismoibg.com/"+urlPerso);
            setAnchorEl(event.currentTarget);
            setTimeout(() => {
                setAnchorEl(null);
            }, 500); // Close the popover after 2 seconds
        } catch (error) {
            console.error('Failed to copy link:', error);
        }
    };

    return (
        <div id="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', alignSelf:"center", justifySelf:"center", maxWidth:"500px" }}>

            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={onClose}
                style={{
                    borderRadius: '15px 15px 0 0',
                    maxHeight:"fit-content",
                    background: 'linear-gradient(180deg, #FF0844 0%, #4808FF 100%)',
                }}
                PaperProps={{
                    sx: {
                        borderRadius: '15px 15px 0 0',
                        background: 'linear-gradient(180deg, #FF0844 0%, #4808FF 100%)',
                        height: 'fit-content !important', // Set the width to fit-content
                    },
                }}
            >
                <div style={{display:"flex", width:"96%", alignSelf:"center", flexDirection:"column", maxWidth:"500px"}}>
                    <span style={{fontSize:"20px", fontWeight:"900",marginTop: '26px',marginLeft: '26px',marginRight: '26px', color:"white"}}>
                        Partagez cette page
                    </span>
                    <div style={{ margin: '20px', display: "flex", flexDirection: "raw", justifyContent: "space-evenly", marginTop: "50px" }}>
                        <div>
                            <IconButton variant="contained" className="button-style-borders-share">
                                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                    <FaFacebook style={{ color: "white" }} />
                                </a>
                            </IconButton>
                        </div>
                        <div>
                            <IconButton variant="contained" className="button-style-borders-share">
                                <a href="https://instagram.com/faismoibg" target="_blank" rel="noopener noreferrer">
                                    <FaInstagram style={{ color: "white" }} />
                                </a>
                            </IconButton>
                        </div>
                        <div>
                            <IconButton variant="contained" className="button-style-borders-share">
                                <a href={`https://api.whatsapp.com/send?text=https://faismoibg.com/${urlPerso}`} target="_blank" rel="noopener noreferrer">
                                    <FaWhatsapp style={{ color: "white" }} />
                                </a>
                            </IconButton>
                        </div>
                        <div style={{ display: "flex" }}>
                            <IconButton variant="contained" className="button-style-borders-share">
                                <a href={`mailto:?body=https://faismoibg.com/${urlPerso}`} target="_blank" rel="noopener noreferrer">
                                    <FaEnvelope style={{ color: "white" }} />
                                </a>
                            </IconButton>
                        </div>
                    </div>
                    <div style={{marginTop:"40px", width:"100%", display:"flex",marginLeft: '10px', flexDirection:"row", marginBottom:"50px", alignContent:"center", alignItems:"center"}}>
                        <FaLink style={{color:"white", fontSize:"1.2rem", width:"10%"}}></FaLink>
                        <span style={{ marginLeft: "4px", color: "white", fontWeight: "600", fontSize: "16px", width: "45%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                             https://faismoibg.com/{urlPerso}
                        </span>
                        <Button onClick={handleCopyLink} className="button-style-noborders-share" variant="contained" style={{marginLeft:"4px", backgroundColor:"black", color:"white", width:"35%"}}>
                            <Typography variant="button" style={{ textTransform: 'none', marginLeft:"2px", marginRight:"2px" }}>
                                Copier
                            </Typography>
                        </Button>
                    </div>
                    <Popover
                        style={{marginTop:"4px"}}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{margin:"6px 32px", color: 'black' }}>Lien copié</Typography>
                    </Popover>
                </div>
            </Drawer>
        </div>
    );
};

export default ShareDrawer;