import { useEffect, useState } from "react";

export const CountdownTimer = ({ dbTimestamp }) => {
    // Initialize timer state
    const [timer, setTimer] = useState('');

    // Countdown logic
    const calculateTimeLeft = async () => {
        const now = new Date().getTime();
        const endTime = new Date(dbTimestamp).getTime() + 30 * 60 * 1000;
        const distance = endTime - now;

        if (distance <= 0) {
            return 'Temps de réservation expiré, veuillez recommencer le processus de réservation.'; // "Ended" in French
        }

        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return `Nous avons bloqué ce créneau pour vous. Il vous reste ${minutes} minutes et ${seconds} secondes pour effectuer l'acompte et valider.`; // "Time remaining" in French
    };

    useEffect(() => {
        const updateTimer = async () => {
            const newTimerValue = await calculateTimeLeft();
            setTimer(newTimerValue);
        };

        // Update the timer for the first time
        updateTimer();

        // Set an interval to update the timer every second
        const intervalId = setInterval(() => {
            updateTimer();
        }, 1000); // Update every second

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [dbTimestamp]); // Re-run the effect if the dbTimestamp changes

    return (
        <div>
            <span style={{fontWeight:700}}>{timer}</span>
        </div>
    );
};