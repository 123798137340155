import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Backdrop, CircularProgress, Skeleton, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import icRightArrow from "../assets/icRightArrow.svg";
import icLeftArrow from "../assets/icLeftArrow.svg";
import Button from "@mui/material/Button";
import SnackbarWithCustomBackground from "./snackbar";
import {firebaseAuth, getAgn, getFreeTimeSlotsv2clbreak} from "../firebase";
import {onAuthStateChanged, signInAnonymously} from "firebase/auth";
import {cleanLocalStorage, loadFromLocalStorage, removeFromLocalStorage} from "./utils";

const months = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
];

const daysInMonth = {
    janvier: 31,
    février: 28,
    mars: 31,
    avril: 30,
    mai: 31,
    juin: 30,
    juillet: 31,
    août: 31,
    septembre: 30,
    octobre: 31,
    novembre: 30,
    décembre: 31,
};

const dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

function formatDayNameAndDate(dayName, dayOfMonth) {
    return `${dayName} ${dayOfMonth}`;
}

function addDaysToDate(baseDate, daysToAdd) {
    const date = new Date(baseDate);
    date.setDate(date.getDate() + daysToAdd);
    return date;
}

function transformWorkedDays(daysWorked, currentMonth, currentYear, prestaEndDate,prestaStartDate, timeBeforePresta) {
    const numDaysInMonth = daysInMonth[currentMonth.toLowerCase()];
    const workedDaysFormatted = [];

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonthIndex = currentDate.getMonth();
    const currentYearValue = currentDate.getFullYear();

    const numericValue = parseInt(timeBeforePresta);

    // Determine the unit of time (hour or day) based on the last character of the input
    const unit = timeBeforePresta.slice(-1) === 'h' ? 'hour' : 'day';

    // Calculate the offset based on the unit
    let offset;
    if (unit === 'hour') {
        offset = 0;
    } else {
        offset = numericValue; // Convert days to milliseconds
    }

    const offsetDate = addDaysToDate(currentDate, offset);

    const stripTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const strippedOffsetDate = stripTime(offsetDate);

    for (let i = 1; i <= numDaysInMonth; i++) {
        const monthIndex = months.indexOf(currentMonth.toLowerCase());

        if (
            (currentYear < currentYearValue) ||
            (currentYear === currentYearValue && monthIndex < currentMonthIndex) ||
            (currentYear === currentYearValue && monthIndex === currentMonthIndex && i < currentDay)
        ) {
            continue; // Skip days earlier than the current date
        }

        const currentDateInM = new Date(currentYear, monthIndex, i);
        const strippedCurrentDateInM = stripTime(currentDateInM);

        if (strippedCurrentDateInM > prestaEndDate || strippedCurrentDateInM < prestaStartDate) {
            continue;
        }

        if (strippedCurrentDateInM < strippedOffsetDate) {
            continue; // Skip if current date is earlier than the offset date
        }

        const dayNameIndex = new Date(currentYear, monthIndex, i).getDay();
        const dayName = dayNames[dayNameIndex];
        const formattedDay = formatDayNameAndDate(dayName, i);
        if (daysWorked.includes(dayName.toLowerCase())) {
            workedDaysFormatted.push(formattedDay);
        }

    }
    return workedDaysFormatted;
}

export default function RdvDate() {

    const { id, id2 } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const location = useLocation();
    const stateData = location.state;
    const [displayPage, setDisplayPage] = useState(null);
    const [agData, setAgData] = useState(null);

    const currentDate = new Date();
    const monthNames = [
        'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ];

    const currentMonthName = monthNames[currentDate.getMonth()];
    const currentDay = currentDate.getDate();
    const dates = [1,3,4,5,7,8,10,11];
    const [daysWorked, setDaysWorked] = useState([]);
    const [showBackdrop, setShowBackdrop] = useState(true);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [currentMonthIndex, setCurrentMonthIndex] = useState(currentDate.getMonth());
    const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
    const [displayMonth, setDisplayMonth] = useState(monthNames[currentDate.getMonth()]);
    const [displayYear, setDisplayYear] = useState(currentDate.getFullYear());
    const [dateItems, setDateItems] = useState([]);
    const [blockButtons, setBlockButtons] = useState(false);
    const [prestaEnd, setPrestaEnd] = useState(null);
    const [prestaStart, setPrestaStart] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [prestaObj, setPrestaObj] = useState(stateData?.prestaObj);
    const queryParams = new URLSearchParams(location.search);
    const rdvId = queryParams.get('rid');

    // Access the state data as needed

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {

        if (loadFromLocalStorage("depositResult")) {
            navigate('/'+id);
        } else {
            cleanLocalStorage();
        }

        const unregisterAuthObserver = onAuthStateChanged(  firebaseAuth,  async ( user) => {
            // if (!(user && user.isAnonymous)) {
            if (!(user && user.isAnonymous)) {
                try {
                    await signInAnonymously(firebaseAuth);
                } catch (e) {
                    console.log("Auth error => ", e.message);
                    setDisplayPage(false);
                }
            }
            if (user){
                if (stateData?.prestaObj){
                    const fetchData = async () => {
                        try {
                            /*const url = `https://us-central1-faismoibg-app.cloudfunctions.net/getAg?id=${stateData.prestaObj.uid}`;
                            const response = await axios.get(url);*/
                            setBlockButtons(true);
                            const response = await getAgn({id:stateData.prestaObj.uid});
                            if (response?.data?.success){
                                //display page
                                setAgData(response.data.agendaData);
                                const prestaEnd = stateData.prestaObj.endTime._seconds * 1000;
                                setPrestaEnd(prestaEnd);
                                const prestaStart = stateData.prestaObj.startTime._seconds * 1000;
                                setPrestaStart(prestaStart);
                                setDisplayPage(true);
                                let daysWorked = [];
                                if (response.data.agendaData.lundiWorked.checked)  daysWorked.push("lundi");
                                if (response.data.agendaData.mardiWorked.checked)  daysWorked.push("mardi");
                                if (response.data.agendaData.mercrediWorked.checked)  daysWorked.push("mercredi");
                                if (response.data.agendaData.jeudiWorked.checked)  daysWorked.push("jeudi");
                                if (response.data.agendaData.vendrediWorked.checked)  daysWorked.push("vendredi");
                                if (response.data.agendaData.samediWorked.checked)  daysWorked.push("samedi");
                                if (response.data.agendaData.dimancheWorked.checked)  daysWorked.push("dimanche");
                                const formattedWorkedDays = transformWorkedDays(daysWorked, monthNames[currentDate.getMonth()], currentDate.getFullYear(), prestaEnd, prestaStart, response.data?.agendaData?.timeBeforePresta);
                                for (let i = formattedWorkedDays.length - 1; i >= 0; i--) {
                                    let day = formattedWorkedDays[i];
                                    if (!stateData.prestaObj.selectedDays.some(selectedDay => day.toLowerCase().includes(selectedDay.toLowerCase()))) {
                                        formattedWorkedDays.splice(i, 1);
                                    }
                                }

                                let promises = [];
                                let retainedDays = [];
                                for (let dayStr of formattedWorkedDays){
                                    const day = parseInt(dayStr.split(" ")[1]);
                                    const month = currentDate.getMonth();
                                    const year = currentDate.getFullYear();
                                    const utcDate = new Date(Date.UTC(year, month, day));
                                    const offsetInMinutes = utcDate.getTimezoneOffset();
                                    const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
                                    const adjustedDate = new Date(utcDate.getTime() + Math.abs(offsetInMilliseconds));
                                    promises.push(getFreeTimeSlotsv2clbreak({ isGoogleSync: response.data.agendaData.googleAGCode,
                                        prestaDuration: prestaObj.prestaDuration, prestaBreak : prestaObj.breakDuration, inputDate: adjustedDate, uid:  response.data.agendaData.uid , dayStr: dayStr
                                    }));
                                }
                                if (promises.length>0){
                                    let retained = await Promise.all(promises);
                                    for (let retain of retained){
                                        if (retain.data.display){
                                            retainedDays.push(retain.data.dayStr)
                                        }
                                    }
                                }

                                setDateItems(retainedDays);
                                setDaysWorked(daysWorked);
                                setBlockButtons(false);
                                setShowBackdrop(false);
                            } else {
                                setDisplayPage(false);
                            }
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            setDisplayPage(false);
                            setShowBackdrop(false);
                        }
                    };
                    await fetchData();

                } else {
                    setDisplayPage(false);
                    setShowBackdrop(false);
                }
            }
        });


        return () => {
            unregisterAuthObserver();
        }

    }, []);

    const navigatePreviousMonth = async () => {
        setBlockButtons(true);
        setShowBackdrop(true);
        // Calculate previous month and its year
        const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
        const previousMonthYear = previousMonthIndex === 11 ? currentYear - 1 : currentYear;
        const previousMonthName = monthNames[previousMonthIndex];

        const realCurrentDate = new Date();
        const realCurrentYear = realCurrentDate.getFullYear();
        const realCurrentMonthIndex = realCurrentDate.getMonth();
        if (previousMonthYear < realCurrentYear || (previousMonthYear === realCurrentYear && previousMonthIndex < realCurrentMonthIndex)) {
            handleOpenSnackbar("Vous ne pouvez pas prendre un RDV pour le mois précédent :)");
            setBlockButtons(false);
            setShowBackdrop(false);
        } else {
            setSelectedItem(null);
            setCurrentMonthIndex(previousMonthIndex);
            setCurrentYear(previousMonthYear);
            setDisplayMonth(previousMonthName);
            setDisplayYear(previousMonthYear);
            const formattedWorkedDays = transformWorkedDays(daysWorked, previousMonthName, previousMonthYear, prestaEnd, prestaStart, agData.timeBeforePresta);
            for (let i = formattedWorkedDays.length - 1; i >= 0; i--) {
                let day = formattedWorkedDays[i];
                if (!stateData.prestaObj.selectedDays.some(selectedDay => day.toLowerCase().includes(selectedDay.toLowerCase()))) {
                    formattedWorkedDays.splice(i, 1);
                }
            }
            let promises = [];
            let retainedDays = [];
            for (let dayStr of formattedWorkedDays){
                const day = parseInt(dayStr.split(" ")[1]);
                const month = previousMonthIndex;
                const year = previousMonthYear;
                const utcDate = new Date(Date.UTC(year, month, day));
                const offsetInMinutes = utcDate.getTimezoneOffset();
                const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
                const adjustedDate = new Date(utcDate.getTime() + Math.abs(offsetInMilliseconds));
                promises.push(getFreeTimeSlotsv2clbreak({ isGoogleSync: agData.googleAGCode,
                    prestaDuration: prestaObj.prestaDuration, prestaBreak : prestaObj.breakDuration, inputDate: adjustedDate,  uid: agData.uid ,dayStr: dayStr
                }));
            }
            if (promises.length>0){
                try {
                    let retained = await Promise.all(promises);
                    for (let retain of retained){
                        if (retain.data.display){
                            retainedDays.push(retain.data.dayStr)
                        }
                    }
                } catch (e) {
                    console.error(e.message);
                    setDateItems([]);
                    setShowBackdrop(false);
                    setBlockButtons(false);
                    handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.");
                    return;
                }
            }
            setDateItems(retainedDays);
            setBlockButtons(false);
            setShowBackdrop(false);
        }

        // setDisplayMonth(previousMonthName);
        // setDisplayYear(previousMonthYear);
    }
    const navigateNextMonth = async () => {
        setBlockButtons(true);
        setShowBackdrop(true);
        setSelectedItem(null);
        const nextMonthIndex = (currentMonthIndex + 1) % 12;
        const nextMonthYear = nextMonthIndex === 0 ? currentYear + 1 : currentYear;
        const nextMonthName = monthNames[nextMonthIndex];
        //console.log("nextMonthIndex ", nextMonthIndex);
        //console.log("nextMonthYear ", nextMonthYear);
        //console.log("nextMonthName ", nextMonthName);

        setCurrentMonthIndex(nextMonthIndex);
        setCurrentYear(nextMonthYear);
        setDisplayMonth(nextMonthName);
        setDisplayYear(nextMonthYear);
        const formattedWorkedDays = transformWorkedDays(daysWorked, nextMonthName, nextMonthYear, prestaEnd, prestaStart, agData.timeBeforePresta);
        for (let i = formattedWorkedDays.length - 1; i >= 0; i--) {
            let day = formattedWorkedDays[i];
            if (!stateData.prestaObj.selectedDays.some(selectedDay => day.toLowerCase().includes(selectedDay.toLowerCase()))) {
                formattedWorkedDays.splice(i, 1);
            }
        }
        //console.log("formattedWorkedDays ", formattedWorkedDays);
        let promises = [];
        let retainedDays = [];
        for (let dayStr of formattedWorkedDays){
            const day = parseInt(dayStr.split(" ")[1]);
            const month = nextMonthIndex;
            const year = nextMonthYear;
            const utcDate = new Date(Date.UTC(year, month, day));
            const offsetInMinutes = utcDate.getTimezoneOffset();
            const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
            const adjustedDate = new Date(utcDate.getTime() + Math.abs(offsetInMilliseconds));
            promises.push(getFreeTimeSlotsv2clbreak({ isGoogleSync: agData.googleAGCode,
                prestaDuration: prestaObj.prestaDuration, prestaBreak : prestaObj.breakDuration, inputDate: adjustedDate, uid: agData.uid , dayStr: dayStr
            }));
        }
        if (promises.length>0){
            try {
                let retained = await Promise.all(promises);
                for (let retain of retained){
                    if (retain.data.display){
                        retainedDays.push(retain.data.dayStr)
                    }
                }
            } catch (e){
                console.error(e.message);
                setDateItems([]);
                setShowBackdrop(false);
                setBlockButtons(false);
                handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.")
                return;
            }

        }
        setDateItems(retainedDays);
        setBlockButtons(false);
        setShowBackdrop(false);
        /*setDisplayMonth(nextMonthName);
        setDisplayYear(nextMonthYear);*/
    }

    const navigateBack = async () => {
        navigate("/"+id+"/details/"+id2);
    }

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleCardClick = (index) => {
        setSelectedItem((prevSelected) => (prevSelected === index ? null : index));
    };

    const navigateToAppointDay = async () => {
        if (selectedItem!=null){
            navigate("/"+id+"/rdvtime/"+id2+`${rdvId ? `?rdvId=${rdvId}` : ''}`,
                {state:
                        {
                            choice:dateItems[selectedItem],
                            month:displayMonth,
                            year:displayYear,
                            userObject : stateData.userObj,
                            prestaObject : stateData.prestaObj,
                            agData : agData,
                            rdvData : stateData?.rdvData
                        }
                });
        } else {
            handleOpenSnackbar('Vous devez séléctionner une date pour continuer.');
        }
    }

    return (
        <div className="sub-container">

            {displayPage===null ? (
                <div className="sub-container-no-top">
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{marginTop:"10px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                </div>
            ) : (
                displayPage ? (
                    <div className="sub-container-no-top" >
                        <span className="title-style">Séléctionner une date</span>
                        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginTop:"10px"}}>
                            <IconButton disabled={blockButtons}
                                        onClick={() => navigatePreviousMonth()}
                                        variant="contained" style={{ color:"white", display:"flex"}}>
                                <img src={icLeftArrow} style={{width:"40px", height:"40px"}}/>
                            </IconButton>
                            <div style={{fontWeight:"700", display:"flex", alignContent:"center", alignItems:"center", justifyContent:"center", textAlign:"center"}}>
                                {displayMonth} {displayYear}
                            </div>
                            <IconButton disabled={blockButtons} onClick={() => navigateNextMonth()}
                                        variant="contained" style={{ color:"white", display:"flex"}}>
                                <img src={icRightArrow} style={{width:"40px", height:"40px"}}/>
                            </IconButton>
                        </div>
                        {showBackdrop && (
                            <div style={{display:"flex", width:"100%", flexDirection:"column",
                                justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                                <Skeleton style={{marginTop:"30px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                                <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                                <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            </div>
                        )}
                        {!showBackdrop && (
                            <div className="date-layout">
                                {dateItems.map((date, index) => (
                                    <div key={index}
                                         className="date-card"
                                         onClick={() => handleCardClick(index)}

                                         style={{background:
                                                 selectedItem === index
                                                     ? "linear-gradient(100deg, #FF0844 0%, #4808FF 100%)"
                                                     : "white",
                                             color: selectedItem === index
                                                 ? "white"
                                                 : "black",
                                             cursor:"pointer"
                                         }}
                                    >
                                        <div style={{margin:"20px"}}>
                                            {date}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}


                        {dateItems.length===0 && !showBackdrop && (
                            <div className="date-card" style={{width:"100%"}}>
                                <div style={{margin:"20px"}}>
                                    <span>Aucune date disponible ce mois</span>
                                </div>
                            </div>
                        )}

                        <div className="button-container">
                            <Button onClick={navigateToAppointDay} className="button-style button-style-noborders" variant="contained">
                                <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                    Continuer
                                </Typography>
                            </Button>
                        </div>

                        <div className="button-container" style={{marginTop:"15px"}}>
                            <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                                <Typography variant="button" style={{ textTransform: 'none' }}>
                                    Annuler
                                </Typography>
                            </Button>
                        </div>
                        <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />

                    </div>
                ) : (
                    <div className="sub-container-no-top">
                        <div style={{display: "flex", width:"80%", padding: "20px", flexDirection: "column", justifyContent: "center", marginTop:"30px",
                            alignItems: "center", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                        <span style={{lineHeight: "24px"}}>
                                            Cette page n'existe pas ou a expiré  🤷
                                        </span>
                        </div>
                    </div>
                )
            )}

        </div>
    );
}