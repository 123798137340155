import React, {useEffect, useState} from "react";
import {cleanLocalStorage, loadFromLocalStorage} from "./utils";
import {Backdrop, CircularProgress, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate, useParams} from "react-router-dom";
import {checkTpDep, remTempRes} from "../firebase";

export const CheckDepositButton = () => {
    const [showButton, setShowButton] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const navigate = useNavigate();
    const {id } = useParams();

    useEffect(() => {
        const depositResult = loadFromLocalStorage("depositResult");
        async function fetchData() {
            if (depositResult){
                const now = new Date().getTime();
                const endTime = new Date(depositResult.date).getTime() + 30 * 60 * 1000;
                const distance = endTime - now;
                if (distance >= 0) {
                    try {
                        const res = await checkTpDep({id:depositResult.docId});
                        if (res.data.hide){
                            setShowButton(false);
                            cleanLocalStorage();
                        }
                    } catch (e){
                        console.log(e);
                    }

                }
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        // Function to update the showButton state
        const updateButtonVisibility = () => {
            const dbObject = loadFromLocalStorage("dbObject");
            const prestaId = loadFromLocalStorage("prestaId");
            const depositResult = loadFromLocalStorage("depositResult");
            const userObject = loadFromLocalStorage("userObject");
            if (depositResult!=null && dbObject!=null && prestaId!=null && userObject?.urlPerso===id) {
                const now = new Date().getTime();
                const endTime = new Date(depositResult.date).getTime() + 30 * 60 * 1000;
                const distance = endTime - now;
                if (distance <= 0) {
                    cleanLocalStorage();
                    setShowButton(false); // Hide button when time is up
                } else {
                    setShowButton(true); // Show button if time is left
                }
            } else {
                cleanLocalStorage();
                setShowButton(false);
            }
        };

        updateButtonVisibility(); // Call once on mount to set initial state

        const intervalId = setInterval(updateButtonVisibility, 1500); // Update every 30s

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, []);

    const navigateFinishDeposit = () => {
        if (loadFromLocalStorage("answers")!=null){
            navigate('/'+id+'/rdvquestions/'+loadFromLocalStorage("prestaId"));
        } else if (loadFromLocalStorage("formData")!=null){
            navigate('/'+id+'/rdvinfo/'+loadFromLocalStorage("prestaId"));
        }
    }

    const handleCancel = async () => {
        const depositResult = loadFromLocalStorage("depositResult");
        if (depositResult){
            setShowBackdrop(true);
            try {
                await remTempRes({
                    tempId:depositResult.docId
                })
            } catch (e){
                console.log(e);
            }
            cleanLocalStorage();
            setShowBackdrop(false);
            navigate('/'+id);
        }
    }

    const handleCloseWindow = async () => {
        setShowButton(false);
        cleanLocalStorage();
    };

    return (
        <div style={{position:"fixed", width:"100%", display:"flex", justifyContent:"center", top:"0", zIndex:101 }}>
            {showButton &&
                <div style={{display:"flex", position:"absolute", width:"100%",maxWidth:"700px" }}>
                    <div style={{display:"flex", flexDirection:"column", width:"100%", boxShadow: "0px 4px 10px 0px rgba(72, 8, 255, 0.25)",
                        background: "linear-gradient(191deg, #FF0844 12.46%, #4808FF 85.72%)", padding:"30px 25px", borderRadius: "0px 0px 15px 15px"
                    }}>
                        <span style={{color:"white", fontSize:"20px", fontWeight:700, lineHeight: "28px"}}>
                            <span style={{textDecorationLine: "underline"}}>Attention</span> : Cliquez sur le bouton ci-dessous pour finaliser l’étape 3 de votre réservation effectuée avec acompte.
                        </span>
                        <Button onClick={navigateFinishDeposit} className="stickyButton white-button" style={{display:"flex", placeSelf:"center",marginLeft:"32px", marginRight:"32px", marginTop:"20px", backgroundColor:"black", width:"80%", zIndex:100}} variant="button">
                            <Typography variant="button" style={{textTransform: 'none', color:"black", fontWeight:400, fontSize:"18px"}}>
                                Finaliser la réservation
                            </Typography>
                        </Button>{/*
                        <span onClick={handleCloseWindow} style={{textDecoration:"underline", color:"white", marginTop:"30px", display:"flex", placeSelf:"center",
                            fontSize:"18px", cursor:"pointer"}}>
                            Réservation déjà validée
                        </span>*/}
                        <span onClick={handleCancel} style={{textDecoration:"underline", color:"white", marginTop:"30px", display:"flex", placeSelf:"center",
                            fontSize:"18px", cursor:"pointer"}}>
                            Annuler la réservation
                        </span>
                    </div>

                </div>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}