import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";

export default function Redirect() {

    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const relativeURL = location.pathname;
                if (relativeURL==="/"){
                    window.location.href = 'https://shine.faismoibg.com';
                }
                const key = relativeURL.replace(/\//g, '');
                const url = `https://us-central1-faismoibg-app.cloudfunctions.net/loadPage?id=${key}`;
                const response = await axios.get(url);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

}