import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from "./components/error";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Redirect from "./components/redirect";
import {createTheme, ThemeProvider} from "@mui/material";
import {frFR, frFR as dataGridFrFR} from '@mui/x-data-grid';
import { frFR as coreFrFR } from '@mui/material/locale';
import Home from "./components/home";
import Footer from "./components/footer";
import {Header} from "./components/header";
import Details from "./components/details";
import RdvDate from "./components/rdvdate";
import RdvTime from "./components/rdvtime";
import RdvInfo from "./components/rdvinfo";
import RdvStatus from "./components/rdvstatus";
import RdvQuestions from "./components/rdvquestions";
import ReactGA from "react-ga4";
import {CheckDepositButton} from "./components/continueDepositButton";
import * as Sentry from "@sentry/react";

ReactGA.initialize("G-W8MWGQ2SNE");

const router = createBrowserRouter([
    {
        path: ":id/rdvquestions/:id2",
        element:  <>
            <div className="container">
                <Header/>
                <div className="container-col">
                    <RdvQuestions />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
        errorElement: <ErrorPage />,
    },
    {
        path: ":id/rdvstatus/:id2",
        element:  <>
            <div className="container">
                <Header/>
                <div className="container-col">
                    <RdvStatus />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:id/rdvinfo/:id2",
        element:  <>
            <div className="container">
                <Header/>
                <div className="container-col">
                    <RdvInfo />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:id/rdvtime/:id2",
        element:  <>
            <div className="container">
                <Header/>
                <div className="container-col">
                    <RdvTime />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:id/rdvdate/:id2",
        element:  <>
            <div className="container">
                <Header/>
                <div className="container-col">
                    <RdvDate />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:id/details/:id2",
        element:  <>
            <div className="container">
               <Header/>
                <div className="container-col">
                    <CheckDepositButton></CheckDepositButton>
                    <Details />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:id",
        element:  <>
            <div className="container">
                <CheckDepositButton></CheckDepositButton>
                <Header/>
                <div className="container-col">
                    <Home />
                    <div className="footer-container">
                        <Footer />
                    </div>
                </div>
            </div>
        </>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/",
        element:  <>
            <Redirect/>
        </>,
        errorElement: <ErrorPage />,
    },
])

const AppTheme = () => {

    const themewrapper = createTheme({
        frFR, // x-date-pickers translations
        dataGridFrFR, // x-data-grid translations
        coreFrFR, // core translations
        palette: {
            primary: {
                main: '#FFFFFF', // Set your desired primary color here
            },
        },
        overrides: {
            MuiSnackbar: {
                root: {
                    width: '100%', // This will make the Snackbar stretch to full width
                },
            },
            MuiSnackbarContent: {
                root: {
                    backgroundColor: 'blue', // Replace with your desired background color
                    color: 'white', // Replace with your desired text color
                },
            },
        },
        typography:
            {
                "fontFamily": `"Nunito", sans-serif`,
                "fontSize": 16,
                "fontWeightLight": 600,
                "fontWeightRegular": 700,
                "fontWeightMedium": 800,
                button: {
                    fontWeight: '700', // Add this line to set the global font weight for button variant
                },
            },
        components: {
            MuiPopper: {
                defaultProps: {
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: false,
                        },
                        {
                            name: 'preventOverflow',
                            enabled: false,
                        },
                    ],
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontWeight: 400,
                        fontSize: "16px", // Set the desired font size for labels
                    },
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                    // Override styles for the toolbar (which contains the buttons)
                    '& button': {
                        border: 'none !important', // Remove the border from all MUI Button components inside the DatePicker
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        borderRadius:"15px",
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        // Your custom styles for MuiPaper-root and MuiPaper-elevation
                        // Add any other styles you want to override here
                        borderRadius:"15px",
                        marginTop:"8px"
                        //boxShadow: "none",
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    paper: {
                        // Your custom styles for MuiPaper-root and MuiAutocomplete-paper
                        // Add any other styles you want to override here
                        background: "white",
                        borderRadius:"15px",
                        backgroundColor:"#F1F5F8",
                        //boxShadow:"none"
                    },
                    listbox:{
                        borderRadius:"15px",
                        backgroundColor:"#F1F5F8",
                        padding: "4px 4px",
                        boxShadow:"none"
                    },
                    popper:{
                        marginTop:"4px !important",
                        padding: "4px 4px",
                    }
                },
            },
            MuiAutocompleteOption: {
                styleOverrides: {
                    option: {
                        // Your custom styles for the option items
                        // Add any other styles you want to override here
                        borderRadius: "8px",
                        margin: "2px 0",
                        background: "#F1F5F8",
                        padding: "8px",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    outlined: {
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'black',
                    },
                    root: {
                        borderRadius: 999,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 15,
                        fontWeight: 400,
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: "#F1F5F8",
                            '&.Mui-focused fieldset': {
                                borderColor: '#000000',
                            },
                        },
                        "& fieldset": { border: 'none' },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#000000',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#000000',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                            color: '#000000',
                        },
                        '& input': {
                            fontSize: '16px !important', // Change the font size as needed
                        },
                        '& textarea': {
                            fontSize: '16px !important', // Change the font size as needed
                        },

                    },
                },
            },
        },
    });

    return (
        //TODO Regularly uncomment strict mode : check that the app run smoothly with it
        /*<React.StrictMode>*/
        <ThemeProvider theme={themewrapper}>
            <div style={{ display: "flex", width: "100%" }}>
                <RouterProvider router={router} />
            </div>
        </ThemeProvider>
        /*
                </React.StrictMode>
        */
    );
};

Sentry.init({
    dsn: "https://1fa3690502cff6265844b2e44f7b8812@o4508443072659456.ingest.de.sentry.io/4508461404717136",
    integrations: [
        Sentry.captureConsoleIntegration({
            levels: ['error'], // Only capture `console.error`
        })
    ],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppTheme />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
