import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {
    FaFacebook,
    FaInstagram,
    FaWhatsapp,
    FaEnvelope,
    FaLink,
    FaTiktok,
    FaFacebookMessenger,
    FaPinterest, FaSnapchat, FaStripe, FaPaperclip, FaYoutube, FaDailymotion, FaGlobe
} from 'react-icons/fa';
import IconButton from "@mui/material/IconButton";
import {Popover, Typography} from "@mui/material";
import {FaPaypal} from "react-icons/fa6";
import icLydia from "../assets/iconLydiaWhite.svg";
import icPaylib from "../assets/iconPaylibWhite.svg";
import icSumUp from "../assets/iconSumUpWhite.svg";
import icWise from "../assets/iconWiseWhite.svg";
import icRevolut from "../assets/iconRevolutWhite.svg";
import icLinktree from "../assets/iconLinktreeWhite.svg";
import icBeacons from "../assets/iconBeacons.svg";
import icAllMyLinks from "../assets/iconAllMyLinksWhite.svg";

const ContactDrawer = ({ isOpen, onClose, urlPerso, socials }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleCopyLink = (event) => {
        try {
            navigator.clipboard.writeText("https://faismoibg.com/"+urlPerso);
            setAnchorEl(event.currentTarget);
            setTimeout(() => {
                setAnchorEl(null);
            }, 800); // Close the popover after 2 seconds
        } catch (error) {
            console.error('Failed to copy link:', error);
        }
    };

    const handleSocialClick = (link) => () => {
        window.open(link, '_blank');
    };

    return (
        <div id="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', alignSelf:"center", justifySelf:"center", maxWidth:"500px" }}>

            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={onClose}
                style={{
                    borderRadius: '15px 15px 0 0',
                    maxHeight:"fit-content",
                    background: 'linear-gradient(180deg, #FF0844 0%, #4808FF 100%)',
                }}
                PaperProps={{
                    sx: {
                        borderRadius: '15px 15px 0 0',
                        background: 'linear-gradient(180deg, #FF0844 0%, #4808FF 100%)',
                        height: 'fit-content !important', // Set the width to fit-content
                    },
                }}
            >
                <div style={{display:"flex", width:"96%", alignSelf:"center", flexDirection:"column", maxWidth:"500px"}}>
                    <span style={{fontSize:"20px", fontWeight:"900",marginTop: '26px',marginLeft: '26px',marginRight: '26px', color:"white"}}>
                        Contacter via
                    </span>
                    <div style={{ width: "100%", marginTop: "20px", marginBottom:"50px",display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", justifyContent: "flex-start", flexWrap:"wrap",marginBottom:"50px" }}>
                            {socials.map((socialLink, index) => {
                                if (socialLink?.link?.length > 0) {
                                    const iconComponent = {
                                        facebook: <FaFacebook style={{ fontSize: '1.25rem', color: "white" }} />,
                                        instagram: <FaInstagram style={{ fontSize: '1.25rem', color: "white" }} />,
                                        tiktok: <FaTiktok style={{ fontSize: '1.25rem', color: "white" }} />,
                                        messenger: <FaFacebookMessenger style={{ fontSize: '1.25rem', color: "white" }} />,
                                        pinterest: <FaPinterest style={{ fontSize: '1.25rem', color: "white" }} />,
                                        snapchat: <FaSnapchat style={{ fontSize: '1.25rem', color: "white" }} />,
                                        whatsapp: <FaWhatsapp style={{ fontSize: '1.25rem', color: "white" }} />,
                                        paypal: <FaPaypal style={{ fontSize: '1.25rem', color: "white" }} />,
                                        lydia: <img src={icLydia} style={{ color: "white", width:"20px", height:"20px" }} />,
                                        paylib: <img src={icPaylib} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover",scale:"1.2" }} />,
                                        stripe: <FaStripe style={{ fontSize: '1.25rem', color: "white", scale:"1.3"  }} />,
                                        sumup: <img src={icSumUp}  style={{ color: "white", width:"20px", height:"20px", objectFit:"cover"}} />,
                                        wise: <img src={icWise} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.8" }} />,
                                        revolut: <img src={icRevolut} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.2" }} />,
                                        linktree: <img src={icLinktree} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover" }} />,
                                        linkinbio: <FaPaperclip style={{ fontSize: '1.25rem', color: "white" }} />,
                                        beacons: <img src={icBeacons} style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.2" }} />,
                                        allmylinks: <img src={icAllMyLinks}  style={{ color: "white", width:"20px", height:"20px", objectFit:"cover", scale:"1.2" }} />,
                                        youtube: <FaYoutube style={{ fontSize: '1.25rem', color: "white" }} />,
                                        dailymotion: <FaDailymotion style={{ fontSize: '1.25rem', color: "white" }} />,
                                        email: <FaEnvelope style={{ fontSize: '1.25rem', color: "white" }} />,
                                        site: <FaGlobe style={{ fontSize: '1.25rem', color: "white" }} />,
                                    }[socialLink?.network];

                                    return (
                                        <IconButton onClick={handleSocialClick(socialLink.link)}  className="button-style-borders-share" key={index} style={{width:"unset", padding: "10px", marginLeft: "5px", marginRight: "5px", marginTop:"10px" }}>
                                            {iconComponent}
                                        </IconButton>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                    <Popover
                        style={{marginTop:"4px"}}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{margin:"6px 32px", color: 'black' }}>Lien copié</Typography>
                    </Popover>
                </div>
            </Drawer>
        </div>
    );
};

export default ContactDrawer;