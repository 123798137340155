import React from 'react';
// Import Swiper styles
import IconButton from "@mui/material/IconButton";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import playIc from "../assets/playSvg.svg";
import imglogo from "../assets/imgIcon.svg";
import trashic from "../assets/trashic.svg";



// Import any custom styles for the carousel if needed

// Import your image URLs array (imagesArray) here or pass it as a prop to the component

// Import and initialize the Swiper modules

const ImageCarousel = ({ imagesArray, onDeleteImage, onAddInstaLink, onRemoveInstaLink, blockButton }) => {

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const openLink = (link) => {
        window.open(link, '_blank');
    };

    const deleteButtonStyle = {
        position: 'absolute',
        top: '5px',
        right: '5px',
        backgroundColor: '#F2F2F2',
        opacity:'90%',
        fontSize: '1.5rem',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const typeIconStyle = {
        position: 'absolute',
        bottom: '5px',
        right: '5px',
        opacity:'94%',
        width: '30px',
        height: '30px',
        zIndex: 1,
    };

    return (
        <div className="image-gallery-container">
            {imagesArray.map((imageUrl, index) => (
                <div key={index} className="image-wrapper">
                    <img onLoad={handleImageLoad}
                         className="fade-in" src={imageUrl.url} alt={`Image ${index + 1}`} />
                    <IconButton
                        disabled={blockButton}
                        style={deleteButtonStyle}
                        onClick={() => onDeleteImage(index)}
                    >
                        {/*<FaTimes style={{color:"black"}}
                        />*/}
                        <img src={trashic} style={{width:"20px", height:"20px"}}/>


                    </IconButton>
                </div>
            ))}
        </div>
    );
};

export default ImageCarousel;