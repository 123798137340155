import {useEffect, useRef, useState} from "react";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";

export const MapComp = ({ latitude, longitude }) => {
    const [map, setMap] = useState(null);
    const mapContainerRef = useRef(null);
    const [isGoogleMapApiLoaded, setGoogleMapApiLoaded] = useState(false);

    const darkModeStyle = [
        { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
        {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
        },
        {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
        },
        {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
        },
        {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
        },
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
        },
        {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
        },
        {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
        },
        {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
        },
        {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
        },
        {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
        },
        {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
        },
        {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
        },
        {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
        },
        // Add more styles here
    ];

    const [containerStyle, setContainerStyle] = useState({
        width: '100%',
        height: '0px' // Initially set to 0
    });


    const center = {
        lat: latitude,
        lng: longitude
    };

    const onLoad = async map => {
        const canvas = map.getDiv().querySelector('canvas');
        if (canvas) {
            canvas.style.borderRadius = '20px';
        }
        setMap(map);
    };

    const onUnmount = async () => {
        setMap(null);
    };

    useEffect(() => {
        const resizeHandler = () => {
            if (mapContainerRef.current) {
                const width = mapContainerRef.current.offsetWidth;
                setContainerStyle({
                    width: '100%',
                    height: `${width}px`
                });
            }
        };

        resizeHandler(); // Initialize the height

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    useEffect(() => {

        if (!window.googleMapsScriptAdded) {
            // Load Google Maps script with API key
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAURMGQDvVdOF1Jt_hKtlaaCKzsLMAfbXQ&libraries=places&language=fr&callback=Function.prototype`;
            script.async = true;
            document.body.appendChild(script);
            script.onload = () => {
                // Script has loaded, you can now use the Google Maps API

                setGoogleMapApiLoaded(true);
            };
            window.googleMapsScriptAdded = true;
            return () => {
            };
        } else {
            setGoogleMapApiLoaded(true);
        }

    },[]);

    return (
        <div ref={mapContainerRef} style={{ width: '100%', marginTop:"30px", zIndex:1,
            //boxShadow:"0px 4px 15px 0px rgba(218, 224, 229, 0.90)",
            borderRadius:"20px", overflow:"hidden"}}>
            {isGoogleMapApiLoaded && (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={16}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    /*options={{
                        styles: darkModeStyle  // Dark mode style
                    }}*/
                >
                    <Marker position={center} />
                </GoogleMap>
            )}
        </div>
    );
};
