import axios from "axios";
import {DateTime} from "luxon";

export const fetchLatLong = async (address) => {
    try {
        const encodedAddress = encodeURIComponent(address);
        const url = `https://api-adresse.data.gouv.fr/search/?q=${encodedAddress}&type=housenumber&autocomplete=0`;

        const response = await axios.get(url);

        if (response.data && response.data.features && response.data.features.length > 0) {
            const { geometry } = response.data.features[0];

            if (geometry && geometry.coordinates) {
                const [longitude, latitude] = geometry.coordinates;
                return { latitude, longitude };
            }
        }

        throw new Error('No coordinates found for the given address.');
    } catch (error) {
        console.error(error);
        throw new Error('An error occurred while fetching the coordinates.');
    }
};

export const saveToLocalStorage = (key, value) => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error('Error saving to localStorage', error);
    }
};

export const loadFromLocalStorage = (key) => {
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue === null) return undefined;
        return JSON.parse(serializedValue);
    } catch (error) {
        console.error('Error reading from localStorage', error);
        return undefined;
    }
};

export const removeFromLocalStorage = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error('Error removing item from localStorage', error);
    }
};

export const cleanLocalStorage = () => {
    try {
        removeFromLocalStorage("depositResult");
        removeFromLocalStorage("formData");
        removeFromLocalStorage("prestaId");
        removeFromLocalStorage("answers");
        removeFromLocalStorage("dbObject");
        removeFromLocalStorage("agendaObject");
        removeFromLocalStorage("selectedTime");
        removeFromLocalStorage("selectedDate");
    } catch (error) {
        console.error('Error removing item from localStorage', error);
    }
};


export function addDurationToTime(duration, baseDate) {
    const regex = /^(\d+)h(\d+)m?$/;
    const match = duration.match(regex);

    if (!match) {
        throw new Error("Invalid duration format");
    }

    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10) || 0;

    const newDate = new Date(baseDate);
    newDate.setHours(newDate.getHours() + hours);
    newDate.setMinutes(newDate.getMinutes() + minutes);

    return newDate;
}

export function parseFrenchDateWithTimezone(dateString, timezone) {
    // Parse the user-provided date string in the assumed Paris timezone
    const frenchToEnglishMonth = {
        janvier: 0,
        février: 1,
        mars: 2,
        avril: 3,
        mai: 4,
        juin: 5,
        juillet: 6,
        août: 7,
        septembre: 8,
        octobre: 9,
        novembre: 10,
        décembre: 11,
    };

    const parts = dateString.split(' ');

    const day = parseInt(parts[1]);
    const month = frenchToEnglishMonth[parts[2]];
    const year = parseInt(parts[3]);
    const timeParts = parts[4].split('h');
    const hours = timeParts[0];
    const minutes = timeParts[1];
    const dateStringWithTimezone = `${year}-${(month+1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    const userDate = DateTime.fromISO(dateStringWithTimezone, { zone: timezone });
    const utcDate = userDate.toUTC();
    return [utcDate.toJSDate(), [day, month, year]];
}
